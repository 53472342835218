/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BServiceTypeDto } from './serviceTypeDto';
import { B2BMenuRecipeDto } from './menuRecipeDto';


export interface B2BMenuDto { 
    id?: number;
    code?: string | null;
    name?: string | null;
    serviceType?: B2BServiceTypeDto;
    menuRecipes?: Array<B2BMenuRecipeDto> | null;
}

