/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BRoleDto } from './roleDto';
import { B2BSiteDto } from './siteDto';
import { B2BCardDto } from './cardDto';


export interface B2BCustomerDto { 
    id?: number;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    matricule?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    onboarded?: boolean;
    acceptedTermsAndConditions?: boolean;
    acceptedContract?: boolean;
    acceptedMarketing?: boolean;
    card?: B2BCardDto;
    lastLogin?: string | null;
    lastPasswordChange?: string | null;
    companyId?: number | null;
    role?: B2BRoleDto;
    azureAdUpn?: string | null;
    azureAdUsername?: string | null;
    otp?: string | null;
    sites?: Array<B2BSiteDto> | null;
}

