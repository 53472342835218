import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter, withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  BrowserUtils,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { API_CONFIGURATION } from 'elior-group.joyfood.packages.order-validator';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { Configuration } from './api/B2B_API';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { RequestParserInterceptor } from './app/shared/interceptors/request-parser.interceptor';
import { environment } from './environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

registerLocaleData(it);
registerLocaleData(en);

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      knownAuthorities: environment.msalConfig.auth.knownAuthorities,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.loginRequest.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(
    environment.apiConfig.webApiUri + '/api/customers/reset-password',
    null
  );
  // "reset-password-validation" is implicitly included by "reset-password"

  // insert the public endpoints above this line

  protectedResourceMap.set(
    environment.apiConfig.webApiUri,
    environment.apiConfig.b2cScopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

const initialNavigation =
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
    : withDisabledInitialNavigation();

if (environment.production) {
  enableProdMode();
}

const configurationFactory = () =>
  new Configuration({
    basePath: environment.apiConfig.webApiUri,
  });

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: Configuration,
      useFactory: configurationFactory,
      multi: false,
    },
    {
      provide: API_CONFIGURATION,
      useFactory: configurationFactory,
      multi: false,
    },
    {
      provide: Configuration,
      useFactory: (authService: MsalService) =>
        new Configuration({
          basePath: environment.apiConfig.webApiUri,
          accessToken: authService.instance.getAllAccounts()[0]?.idToken,
        }),
      deps: [MsalService],
      multi: false,
    },
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'it',
      })
    ),
    TranslateStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestParserInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    provideRouter(routes, initialNavigation),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
});

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n/';
  return new TranslateHttpLoader(http, baseTranslateUrl, '.json');
}
