/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BBromatologicalDto } from './bromatologicalDto';
import { B2BUnitType } from './unitType';


export interface B2BBromatologicalValueDto { 
    bromatological?: B2BBromatologicalDto;
    value?: number;
    unit?: B2BUnitType;
}
export namespace B2BBromatologicalValueDto {
}


