const tenantName = 'joyfoodsyn';
const CLIENT_ID = '852a1404-ea38-4ef0-b9e6-a5a3f526cc8e';
const API_ID = '97dadf5c-3d9c-43a8-9869-b2c8d8f04e4d';

export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  msalConfig: {
    auth: {
      clientId: CLIENT_ID,
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/B2C_1A_SIGNUP_USERNAMEOREMAIL`,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
      redirectUri: 'https://b2b.dev.joyfoodplus.elior.com',
    },
  },
  apiConfig: {
    b2cScopes: [`https://${tenantName}.onmicrosoft.com/${API_ID}/fullaccess`],
    webApiUri: 'https://api.b2b.dev.joyfoodplus.elior.com',
  },
  loginRequest: {
    scopes: [`https://${tenantName}.onmicrosoft.com/${API_ID}/fullaccess`],
  },
  termsAndConditionsLink: 'https://www.elior.it/note-legali'
};
