import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class RequestParserInterceptor implements HttpInterceptor {
  translateService: TranslateService | null;

  constructor(
    private notification: NzNotificationService,
    private readonly injector: Injector,
    private router: Router
  ) {
    this.translateService = null;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    try {
      this.getTranslationService();

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error.title == 'Unauthorized')
            this.router.navigate(['no-permission']);

          if (error.error instanceof ErrorEvent) {
            throw error;
          }

          const errorObj = error.error.errors;

          if (errorObj) {
            this.createNotifications(errorObj);
          }

          throw errorObj;
        })
      );
    } catch {
      return next.handle(request);
    }
  }

  private createNotifications(errors: NotificationResponseModel[]): void {
    for (const error of errors) {
      const textToTranslate: string[] = [
        error.key ?? '',
        ...(error.messages ?? []),
      ];

      this.translateService?.get(textToTranslate).subscribe((result) => {
        this.notification.create(
          'error',
          result[error.key ?? ''] || 'Error',
          error.messages?.reduce((str, err) => {
            if (!err) return '';

            return str + (result[err] ?? err);
          }, '') ?? ''
        );
      });
    }
  }

  private getTranslationService(): void {
    if (this.translateService) {
      return;
    }

    this.translateService = this.injector.get(TranslateService);
  }
}

interface NotificationResponseModel {
  key?: string;
  messages?: string[];
}
