/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BUpdateOrderItem } from './updateOrderItem';


export interface B2BUpdateOrderCommand { 
    id?: number;
    customerId?: number;
    serviceTypeId?: number;
    shopId?: number;
    status?: number;
    numberOfCustomers?: number | null;
    orderItems?: Array<B2BUpdateOrderItem> | null;
}

