/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BCompositionDto } from './compositionDto';


export interface B2BAgreementDto { 
    id?: number;
    agreementCategoryId?: number;
    isExtraEnabled?: boolean | null;
    dishLimitNumber?: number;
    endDate?: string;
    startDate?: string;
    companyId?: number;
    pointNumber?: number | null;
    pointPrice?: number | null;
    valueLimit?: number | null;
    companyFlatAmount?: number | null;
    employeeFlatAmount?: number | null;
    compositions?: Array<B2BCompositionDto> | null;
}

