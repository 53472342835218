/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BCreateOrderItem } from './createOrderItem';


export interface B2BCreateOrderCommand { 
    customerId?: number | null;
    azureAdUpn?: string | null;
    selectedDate?: string;
    shopId?: number;
    serviceTypeId?: number;
    numberOfCustomers?: number | null;
    orderItems?: Array<B2BCreateOrderItem> | null;
}

