/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BShopServiceDto } from './shopServiceDto';
import { B2BShopClosingDateDto } from './shopClosingDateDto';


export interface B2BShopDto { 
    id?: number;
    name?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    addressZipCode?: string | null;
    addressCity?: string | null;
    enShortName?: string | null;
    trayLimit?: number;
    hideIncompleteArticles?: boolean;
    orderPlacementRange?: number;
    shopServices?: Array<B2BShopServiceDto> | null;
    shopClosingDates?: Array<B2BShopClosingDateDto> | null;
}

