/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BArticleIngredientDto } from './articleIngredientDto';
import { B2BAllergensDto } from './allergensDto';
import { B2BBromatologicalValueDto } from './bromatologicalValueDto';
import { B2BCategoryDto } from './categoryDto';
import { B2BDietDto } from './dietDto';


export interface B2BArticleDto { 
    id?: number;
    name?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    code?: string | null;
    expirationDayNumber?: number;
    price?: number | null;
    points?: number | null;
    isFree?: boolean;
    rating?: number | null;
    customerRating?: number | null;
    isFavorite?: boolean;
    articlePriceId?: number;
    category?: B2BCategoryDto;
    allergens?: Array<B2BAllergensDto> | null;
    bromatologicalValues?: Array<B2BBromatologicalValueDto> | null;
    diets?: Array<B2BDietDto> | null;
    articleIngredients?: Array<B2BArticleIngredientDto> | null;
}

