export * from './agreements.service';
import { AgreementsService } from './agreements.service';
export * from './articles.service';
import { ArticlesService } from './articles.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './menus.service';
import { MenusService } from './menus.service';
export * from './orders.service';
import { OrdersService } from './orders.service';
export * from './serviceTypes.service';
import { ServiceTypesService } from './serviceTypes.service';
export * from './shopClosingDates.service';
import { ShopClosingDatesService } from './shopClosingDates.service';
export * from './shops.service';
import { ShopsService } from './shops.service';
export * from './sites.service';
import { SitesService } from './sites.service';
export const APIS = [AgreementsService, ArticlesService, CustomerService, MenusService, OrdersService, ServiceTypesService, ShopClosingDatesService, ShopsService, SitesService];
