/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BArticlePriceOrderExportDto } from './articlePriceOrderExportDto';


export interface B2BOrderItemOrderExportDto { 
    id?: number | null;
    quantity?: number;
    unitPointCost?: number;
    unitPrice?: number;
    articlePrice?: B2BArticlePriceOrderExportDto;
}

