/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BShopDto } from './shopDto';
import { B2BCustomerDto } from './customerDto';
import { B2BOrderItemsDto } from './orderItemsDto';


export interface B2BOrderDto { 
    id?: number | null;
    deliveryDate?: string;
    created?: string;
    status?: number;
    statusName?: string | null;
    serviceTypeId?: string | null;
    numberOfCustomers?: number;
    customer?: B2BCustomerDto;
    shop?: B2BShopDto;
    orderItems?: Array<B2BOrderItemsDto> | null;
}

