/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { B2BCustomerOrderExportDto } from './customerOrderExportDto';
import { B2BOrderItemOrderExportDto } from './orderItemOrderExportDto';


export interface B2BOrderExportDto { 
    id?: number | null;
    deliveryDate?: string;
    created?: string;
    status?: number;
    serviceTypeId?: number;
    shopName?: string | null;
    customer?: B2BCustomerOrderExportDto;
    orderItems?: Array<B2BOrderItemOrderExportDto> | null;
}

