import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, tap } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import {
  B2BAcceptTermsCommand,
  B2BChangePasswordCommand,
  B2BCustomerDto,
  CustomerService,
} from 'src/api/B2B_API';
import { TermsAndConditionsModel } from 'src/app/pages/terms-and-conditions/model/terms-and-conditions.model';
import { PasswordChangeModel } from 'src/app/pages/root/password-change/password-change-form/model/password-change.model';

type CustomerPendencies = 'newpassword' | 'onboarding' | 'none';

@Injectable({
  providedIn: 'root',
})
export class CustomerLocalService extends BaseService {
  private meSubject = new BehaviorSubject<B2BCustomerDto | null>(null);
  me$ = this.meSubject.asObservable();

  private customerPendencies = new BehaviorSubject<CustomerPendencies>('none');
  pendenciesStatus$ = this.customerPendencies
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(http: HttpClient, private customerApiService: CustomerService) {
    super(http);
    this.me$.subscribe({
      next: () => this.checkCustomerPendencies(),
    });
  }

  get userDetails() {
    return this.meSubject.value;
  }

  get customerDetails() {
    return this.meSubject.value;
  }

  fetchCurrentUser$() {
    return this.customerApiService.apiCustomerMeGet().pipe(
      tap((me) => {
        this.meSubject.next(me);
      })
    );
  }

  checkCustomerPendencies() {
    if (!this.customerDetails) return;

    const { lastPasswordChange, onboarded } = this.customerDetails;

    !lastPasswordChange
      ? this.customerPendencies.next('newpassword')
      : this.customerPendencies.next('none');
  }

  acceptTerms(
    termsAndConditionsModel: TermsAndConditionsModel | null
  ): Observable<any> {
    const command: B2BAcceptTermsCommand = {
      azureAdUpn: '',
      acceptedMarketing: termsAndConditionsModel?.acceptedMarketing ?? false,
    };

    return this.customerApiService.apiCustomerAcceptTermsPut(command);
  }

  saveNewPassword(
    passwordChangeModel: PasswordChangeModel | null
  ): Observable<any> {
    const command: B2BChangePasswordCommand = {
      currentPassword: passwordChangeModel?.currentPassword!,
      newPassword: passwordChangeModel?.newPassword!,
      passwordConfirmation: passwordChangeModel?.passwordConfirmation!,
      azureAdUpn: '',
    };

    return this.customerApiService.apiCustomerNewPasswordPut(command);
  }
}
