import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { NgZorroAntdModule } from './shared/modules/ng-zorro-antd.module';
import { AccountLocalService } from './shared/services/account.service';
import { CacheService } from './shared/services/cache.service';

register();

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TranslateModule, NgZorroAntdModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'EliorGroup.Joyfood.B2B.Desktop';
  checked = true;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public environmentInjector = inject(EnvironmentInjector);
  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translate: TranslateService,
    private accountService: AccountLocalService,
    private cacheService: CacheService,
  ) {
    var lang = cacheService.getItem('language');
    translate.setDefaultLang(lang || 'it');
    translate.use(lang || 'it');
    cacheService.setItem('language', lang || 'it');
  }

  ngOnInit(): void {
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        this.accountService.setAccounts(
          this.authService.instance.getAllAccounts()
        );
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.accountService.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(() => {
        this.accountService.checkAndSetActiveAccount();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
