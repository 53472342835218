import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, take } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { CustomerLocalService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class AccountLocalService extends BaseService {
  private activeAccountSubject = new BehaviorSubject<AccountInfo | null>(null);
  activeAccount$ = this.activeAccountSubject.asObservable();

  private allAccountSubject = new BehaviorSubject<AccountInfo[] | null>(null);
  allAccounts$ = this.allAccountSubject.asObservable();

  constructor(
    http: HttpClient,
    private authService: MsalService,
    private customerService: CustomerLocalService
  ) {
    super(http);
  }

  setActiveAccount(activeAccount: AccountInfo | null) {
    this.activeAccountSubject.next(activeAccount);

    if (activeAccount != null)
      this.customerService.fetchCurrentUser$().subscribe();
  }

  setAccounts(accounts: AccountInfo[]) {
    this.allAccountSubject.next(accounts);
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.setActiveAccount(accounts[0]);
      this.authService.instance.setActiveAccount(accounts[0]);
    } else if (activeAccount) {
      this.setActiveAccount(activeAccount);
    }
  }

  logoutAccount() {
    this.authService.logout().pipe(take(1)).subscribe();
  }
}
